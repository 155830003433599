/* Old brand colors */
//$brand-primary:         #cd5c05;
$brand-boratt:          #2d5488;
$brand-seniorgarden:    #003a65;

// Primary colors
// Orange
$active-orange:         #B85105;
$brick-orange:          #d15c05;
$dull-orange:           #db7831;
$faded-orange:          #e29457;
$apricot:               #ebaf80;
$pale-peach:            #f3dbb2;
$light-peach:           #f5e2c4;
$pale-three:            #f9ecd6;
$sand:                  #fbf3e7;
$sunny_beach:           #fdfbf7;
// Grayscale
$premiumgrey:           #3f3f3c;// 63 63 60
$medium-grey:           #636360;
$green-grey:            #858582;
$greyish:               #a6a5a3;
$beige:                 #c2c2c0;
$light-grey:            #dad9d3;
$pale:                  #e4e3de;
$pale-two:              #edecea;
$off-white-two:         #f6f6f4;


// Secondary colors
$vip-sunrise:           #e2ad41;//226 173 65
$french-blue:           #4878b0;
$yay:                   #6baa99;//107 170 153
$greyish-brown:         #785f52;
$dark-sage:             #5c6a4a;
$oh-no:                 #e07357;


// Extras
$snow:                  #fff;
$bw-000:                #000;
$switch-red:            #e29090;

